export default {
  namespaced: true,
  state: {
    formulariosPaginado: [],
    quantidadeFormularios: 0,
    pagina: 1,
    linkGerado: '',
    formularioSelecionado: ''
  },
  getters: {
    getPagina(state) {
      return state.pagina
    },
    getFormulariosPaginado(state) {
      return state.formulariosPaginado
    },
    getQuantidadeFormularios(state) {
      return state.quantidadeFormularios
    },
    getLinkGerado(state) {
      return state.linkGerado
    },
    getFormularioById(state) {
      return state.formularioSelecionado
    },
  },
  mutations: {
    setPagina(state, payload) {
      state.pagina = payload
    },
    setFormulariosPaginado(state, payload) {
      state.formulariosPaginado = payload
    },
    setQuantidadeFormularios(state, payload) {
      state.quantidadeFormularios = payload
    },
    setLinkGerado(state, payload) {
      state.linkGerado = payload
    },
    setFormularioSelecionado(state, payload) {
      state.formularioSelecionado = payload
    }
  },
};
