<script setup>
import SideBar from "@/components/SideBar/SideBar.vue"
import ToolBar from "@/components/ToolBar/ToolBar.vue"
import store from "@/store"
import { computed } from 'vue'

const themeIsDark = computed(() => store.state.themeIsDark)
const privilegio = computed(() => store.getters['authModule/getUserPrivilegio'])
</script>

<template>
    <SideBar v-if="$route.meta.requiresAuth && $route.meta.roles.includes(privilegio)" />
    <div id="content" :class="{'modo-escuro': themeIsDark}">
      <ToolBar :class="{'modo-escuro': themeIsDark}" v-if="$route.name != 'Feedback'"/>
      <router-view :class="{'modo-escuro': themeIsDark}"/>
    </div>
</template>

<style lang="scss">
#content {
  width: 100vw;
  overflow: auto;
  background-color: var(--secondary);
}
</style>
