import { computed } from "@vue/reactivity"
import { createRouter, createWebHashHistory } from 'vue-router'
import store from "../store/index"

const usuario = computed(() => store.getters['authModule/getUser'])
const isInstrutor = computed(() => store.getters['authModule/isInstrutor'])
const isGestor = computed(() => store.getters['authModule/isGestor'])

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/pages/DashboardView.vue'),
    requiresAuth: true,
    roles: []
  },
  {
    path: '/formularios/',
    name: 'Formulários',
    meta: {
      requiresAuth: true,
      roles: ['ADMINISTRADOR', 'SUPERVISOR_UCA', 'GESTOR', 'INSTRUTOR']
    },
    component: () => import('@/pages/FormulariosView.vue')
  },
  {
    path: '/relatorios/',
    name: 'Relatórios',
    meta: {
      requiresAuth: true,
      roles: ['ADMINISTRADOR', 'SUPERVISOR_UCA', 'GESTOR', 'INSTRUTOR']

    },
    component: () => import('@/pages/RelatoriosView.vue')
  },
  {
    path: '/instrutores/',
    name: 'Instrutores',
    meta: {
      requiresAuth: true,
      roles: ['ADMINISTRADOR', 'SUPERVISOR_UCA', 'GESTOR']

    },
    component: () => import('@/pages/InstrutoresView.vue')
  },
  {
    path: '/treinamentos/',
    name: 'Treinamentos',
    meta: {
      requiresAuth: true,
      roles: ['ADMINISTRADOR', 'SUPERVISOR_UCA', 'GESTOR']

    },
    component: () => import('@/pages/TreinamentosView.vue')
  },
  {
    path: '/avaliacoes/',
    name: 'Avaliações',
    meta: {
      requiresAuth: true,
      roles: ['ADMINISTRADOR', 'SUPERVISOR_UCA', 'GESTOR']

    },
    component: () => import('@/pages/AvaliacoesView.vue')
  },
  {
    path: '/feedback/:id',
    name: 'Feedback',
    meta: {
      requiresAuth: false,
      roles: []
    },
    component: () => import('@/pages/FeedbackView.vue')
  },
  {
    path: '/unauthorized/',
    name: 'Unauthorized',
    meta: {
      requiresAuth: false,
      roles: []

    },
    component: () => import('@/pages/UnauthorizedView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {

  if (to.name === 'Feedback') {
    next()
  } else if (to.name === 'Dashboard' && (isInstrutor.value || isGestor.value)) {
    next('/formularios/');
  } else if (to.name === 'Unauthorized' && usuario.value.status) {
    next('/');
  }else if (
    !(to.meta.requiresAuth && to.meta.roles.includes(usuario.value.privilegio))
    && !['Dashboard', 'Unauthorized', 'Feedback'].includes(to.name)
  ){
    next('/');
  } else if (!usuario.value.status && to.name !== 'Unauthorized') {
    next('/unauthorized/');
  } else {
    next()
  }
})

export default router
